'use client';
import { useEffect } from 'react';

import Tracker from '@openreplay/tracker';

import { UserResponse } from '@bloom/codegen/models/UserResponse';

const tracker = process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY
  ? new Tracker({ projectKey: process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY })
  : null;

const Openreplay: React.FC<{ user?: UserResponse | null }> = (props) => {
  const { user } = props;

  useEffect(() => {
    if (tracker) {
      if (typeof window !== 'undefined' && !tracker.isActive()) {
        tracker.start().then((startedSession) => {
          if (startedSession.success) {
            console.log('OpenReplay started');
          } else {
            console.error('OpenReplay failed to start', startedSession.reason);
          }
        });
      }

      if (user) {
        tracker.setUserID(user.email);
      }
    }
  }, [user]);

  return null;
};

export default Openreplay;
